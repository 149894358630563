<div class="row">
    <div class="col-md-12 nopad">

        <div class="col-md-12 nopad">
            <div class="w_79"
                style="position: fixed;z-index: 99;  font-size: 13px; margin-top: -1px; font-weight: 600; color: darkslategray; padding-right: 5px;padding-left: 5px; z-index: 999; background-color: white; ">

                <table style="width: 100%;">
                    <tr>
                        <td style="color: darkslategray;">
                            <span>{{appservice.header_Row.dc_ledger_name}} </span>
                            
                            <br>
                            {{appservice.header_Row.dc_contact_no}}
                        </td>
                        <td style="text-align: right;  font-size: 12px;    color:#333333;">

                            {{appservice.header_Row.dc_bill_no}}<br>
                            {{appservice.Today_Date}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <select placeholder="Select Category..." style="border-radius: 15px!important;"
                                class="select2-option form-control form-control-sm" aria-hidden="true"
                                [(ngModel)]="appservice.item_Category_" placeholder=" " name="Category"
                                (change)="get_filter_data()" #Category="ngModel" required>
                                <option value="All">
                                    All
                                </option>

                                <option *ngFor="let row of appservice.get_ref('Item_Category')" value={{row.label}}>
                                    {{row.label}}
                                </option>

                            </select>

                        </td>
                        <td ><input type="text" placeholder="Search item.."   
                            [(ngModel)]="Search_text_" name="Search_text"
                                  #Search_text="ngModel" 
                              (keyup)="search_data(Search_text_)" style="border-radius: 15px!important;"
                               class="form-control form-control-sm"></td>  
                      
                    </tr>

                </table>



                <div>


                </div>

            </div>
        </div>



        <div class="col-md-12 nopad">


            <div class="spin_block" *ngIf="appservice.loading">

                <div class="d-flex justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

            </div>



        </div>

        <div class="m_2 res_table" style="margin-top: 75px;">


            <div *ngIf="(((appservice.Item_Master_Rows | json) == '[]') ||  ((appservice.Item_Master_Rows | json) == null) )">
                <div class="d-table" style="width:100%;">
                    <div class="d-table-cell">
                        <div class="maintenance-content" style="box-shadow:none ; background: none; ">

                            <a style="height: 100px; width: 100px;" class="logo">
                                <img src="assets/img/cart-256.png" alt="image">
                            </a>

                            <p>Item not availabel please select other category</p>


                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-12 " style="background-color: #dbe4ec4d;">



                <div class="row">
                    <div class="col-sm-4 col-md-3 " style="padding: 1px;" *ngFor="let data of appservice.Item_Master_Rows">
                        <div class="Cart-Card">
                            <div class="pro-content" style="height: auto;">
                                <div class="prod-Name" style="color: rgb(20, 20, 20);">{{data.Item_Name}}/MRP:{{data.MRP}}
                                </div>


                            </div>
                            <div class="mrp" style="color: rgb(26, 26, 27);
                        font-weight: 600;">
                                {{data.Description}} {{data.Category}} 


                                <span style="position: absolute; right: 10px;"> GST :{{data.GST_Per}}%</span>
                            </div>

                            
                            <div class="mrp" style="padding-bottom: 25px; color:#289428 ; font-weight: 800;">
                                Stock : <span>-{{data.Stock}}</span>


                                <span style="position: absolute; right: 10px; font-size: 10px; color: darkgray;">
                                    {{data.L_Rate}} </span>
                            </div>




                            <div class="mrp">
                                <div style="position: absolute; left: 10px; bottom: 20px; width: 40%;">

                                    <li class="bx bx-rupee" style="    position: absolute;  left: 0px;  COLOR: BLACK;
                                      bottom: 5px;"></li>
                                    <input type="number" style="width: 100%; padding-left: 15px;
                                    border-color: gainsboro; background: #f4fff51a; border-style: unset; border-bottom-style:solid;" 
                                    [(ngModel)]="data.Rate" name="Order_Rate" #Order_Rate="ngModel"
                                        (keyup)="add_row(data)" placeholder="Rate ">
                                </div>
                                <div style="position: absolute; right: 10px;  bottom: 20px;width: 40%;display: inline-flex;">

                                    <li (click)="remove_One(data)" style="font-size: 28px;
                                    color: #e4484f;"  class="bx bx-minus-circle icons"></li>
                                   
                                   

                                    <input type="number" (keyup)="add_row(data)" [(ngModel)]="data.Order_Qty"
                                        name="Order_Qty" #Order_Qty="ngModel"
                                        style=" font-size: 13px; text-align: center; max-width: 150px;width: 100%;border-color: gainsboro; background: #f4fff51a;border-style: unset;border-bottom-style: solid;"
                                        placeholder="Qty">

                                        <li  style="font-size: 28px;
                                        color: #e4484f;" (click)="add_One(data)"  class="bx bxs-plus-circle icons"></li>
                            
                                </div>
                            </div>

                            <div class="mrp" style="position: absolute; bottom: -3px; text-align: center;">
                                <span style="font-size: 10px; color:red;"> {{data.error}}</span>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12 nopad">
            <div class="w_79" style="  bottom: 0;
                font-weight: 700;
                position: fixed;">
                <table style="width: 100%;">
                    <tr>
                        <td style="text-align: right;">
                            <a [class.disabled]="isload"
                                style="border-radius: 0px;color: white;"
                                class="btn btn-info btn-block">

                                <span class="bx bx-basket" role="status" aria-hidden="true"></span>

                                {{Total}} </a>
                        </td>

                        <td style="color: white;">
                            <a style="border-radius: 0px;" (click)="next()"
                                class="btn btn-danger btn-block">Next</a>

                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>