<div class="row">
    <div class="col-md-12 nopad">

        <div class="search-bar">
            <table style="width: 100%;">
                <tr style="width:100%;height: 50px;">
                    <td class="search-bal-li">
                        <li (click)="appservice.back()" class="bx bx-left-arrow-alt"
                            style=" font-size: 30px; line-height: 50px;"></li>
                    </td>
                    <td><input  type="text" 
                        (keydown.enter)="selected_row1(row_No,dt)" 
                        (keydown.arrowup)="up()"  
                        (keydown.arrowdown)="down()"  
                        (input)="dt.filterGlobal($event.target.value, 'contains')" 
                        class="search-form-control text   itext"
                        placeholder="Search..."></td>

                    <td class="search-bal-li">

                        <div class="dropdown"style="display: inline-flex;">
                            <button class="dropdown-toggle mhide abutton" type="button" (click)="addReset()" title="Add New" >
                                <i style="font-size: 19px;" class='bx bx-list-plus'></i> 
                                <span >Add New</span>
                              </button>
                              <a class="dropdown-toggle  mshow addbtn1" (click)="addReset()" data-toggle="tooltip" data-placement="bottom"
                                title="Add New">
                  
                                <i class='bx bx-list-plus'></i>
                              </a>
                        </div>

                    </td>

                  

                </tr>
            </table>
        </div>


    </div>
</div>
<div class="row">
    <div class="col-md-12 nopad">
        <!-- <div *ngIf="appservice.S_Area!='All'" 
         style="width: 100%;padding: 5px; text-align:center; ">  Selected Area : {{appservice.S_Area}}</div> -->
        <div class="">

            <div class="spin_block" *ngIf="appservice.isload">

                <div class="d-flex justify-content-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
        
              </div>
              
  
  
            <p-table #dt class="table table-striped custom-table" 
            [value]="appservice.SProject_Rows" 
            [globalFilterFields]="appservice.Est_Search_GF"
            selectionMode="single">
            <ng-template pTemplate="header">
                <tr>
                  <th>#</th>
                  <th>Tender Date </th>
                    <th>Department </th>
                    <th>Office </th>
                    <th>Project Name </th>
                    <th>Net Amt </th>
                  
                </tr>

             
            </ng-template>
            <ng-template class="trhover"  pTemplate="body" let-rowData let-car let-rowIndex="rowIndex">
                <tr  class="trhover"   (click)="select_Ledger(rowData)">

                  <td [className]="rowIndex==row_No ? 'rselected' : ''"  >{{rowIndex+1}}</td>
                  <td [className]="rowIndex==row_No ? 'rselected' : ''"   > {{rowData.est_tenderdate}}

                    <td [className]="rowIndex==row_No ? 'rselected' : ''"   > {{rowData.est_department}}

                    </td>
                    <td  [className]="rowIndex==row_No ? 'rselected' : ''"   > {{rowData.est_office}}</td>
                    <td [className]="rowIndex==row_No ? 'rselected' : ''"   > {{rowData.est_projectname}}</td>
                    <td [className]="rowIndex==row_No ? 'rselected' : ''"   > {{rowData.est_net_amt}}</td>

                </tr>
            </ng-template>
        </p-table>

          </div>
    </div>
</div>